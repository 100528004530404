export const ownership = {
  landing: {
    nextUp: 'Next up:',
    willNeed: {
      SSN: 'Social Security Number (SSN)',
      personal: 'Birthday, home address, phone number, email',
      ownership: 'Ownership percentage',
      financialAdmin: 'Designate a financial administrator',
    },
    corporation: {
      title: 'Create a profile for yourself and any co-owners',
      subtitle:
        "You'll need the following information for everyone who owns 25% or more of the business and anyone with control of your business’ finances:",
    },
    soleProprietorship: {
      title: 'Create your profile',
      subtitle: "You'll need the following information:",
    },
  },
  profile: {
    pageHeading: '{{businessName}} owner profiles',
    subHeading:
      'You will be asked to create an owner profile for every co-owner you have that owns 25% or more of the business.',
    primaryOwnerHeading: 'Complete your owner profile',
    tooltipWhyCollectPersonalInfoContent:
      'North One is required to collect personal information such as first and last name, social security number, job title, date of birth, phone number, and address to verify your identity and ensure compliance with legal and regulatory requirements. This helps prevent fraud, money laundering, and identity theft. Additionally, it allows North One to assess your profile and provide appropriate services tailored to your needs.',
    coOwnerHeading: "Complete your co-owner's profile",
    addCoOwner: 'Add a legal co-owner',
    addCoOwnerDescription: 'Anyone who owns 25% or more',
    missingSSNAlert:
      'Your session ended before we could verify your identity. For your security we did not save your SSN. Please re-enter it.',
    required: 'Required',
    reviewingYourOwnership: 'Reviewing your ownership',
    deleteCoOwnerModal: {
      heading: 'Are you sure you want to delete this co-owner?',
      subHeading: 'This information will not be saved.',
    },
    labels: {
      firstName: 'Legal first name',
      lastName: 'Legal last name',
      jobTitle: 'Job title',
      dateOfBirth: 'Date of birth',
      phoneNumber: 'Phone number',
      addressAutocomplete: 'Home address',
      ssnLong: 'Social Security Number (SSN)',
      ssnShort: 'SSN',
      streetAddress: 'Home street address',
      suite: 'Apartment, suite, etc (optional)',
      city: 'City',
      state: 'State',
      zip: 'ZIP Code',
      ownership: 'Ownership',
      ownershipPercentage: 'Ownership percentage of {{businessName}}',
      email: 'Email address',
      coownerCheckboxInvite:
        "Send your co-owner an invite to manage your business' North One Account. They'll receive a debit card and will have the same level of access as you do.",
      financialAdminCheckboxInvite:
        "Send your financial administrator an invite to manage your business' North One Account. They'll receive a debit card and will have the same level of access as you do.",
      invitePending: 'Invite pending',
      delete: 'Delete',
      edit: 'Edit',
    },
    placeholders: {
      addressAutocomplete: 'Search home address',
    },
    helperText: {
      ssn: 'Your SSN is stored securely',
      streetAddress: 'PO boxes are not accepted',
      whyDoWeCollectContactInfo:
        'North One is required to collect other account holder’s information such as first and last name, social security number, job title, date of birth, phone number, and address to verify their identities and ensure compliance with legal and regulatory requirements. This information is necessary to establish the legitimacy of all account holders and protect the security of the account.',
    },
    emailSuggestion: 'This email address doesn’t look right. Did you mean ',
    errors: {
      ownershipPercentage: "Your business' ownership structure exceeds 100%. Please re-assign the shares.",
    },
  },
  review: {
    header: '{{businessName}} ownership',
    subHeader: 'Create an owner profile for every co-owner you have that owns 25% or more of the business.',
    addOwner: {
      header: 'Add a legal co-owner',
      subHeader: 'Anyone who owns 25% or more',
    },
  },
} as const
